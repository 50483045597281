<template>
  <div class="date-picker">
    <span class="date-picker__icon">
      <i class="material-icons">today</i>
    </span>
    <flat-pickr v-model="model"
                :config="config"
                :placeholder="placeholder !== undefined ? placeholder : $t('form_fields.choose_a_date')"
    ></flat-pickr>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

export default {
  name: "DatePicker",
  components: {
    flatPickr
  },
  props: {
    value: {
    },
    settings: {
      type: Object,
    },
    placeholder: {
      type: String,
    }
  },
  data() {

    const config = {
      altInput: true,
      dateFormat: "Y-m-d",
      altFormat: "d-m-Y",
      altInputClass: 'form-control'
    }

    return {
      model: this.value,
      config: {...this.settings, ...config}
    }
  },
  watch: {
    model(currentValue) {
      this.$emit('input', currentValue)
    }
  }
}
</script>